import React from "react";
import Layout from "../components/Layout";
import MembershipForm from "../components/MembershipForm";
import SheetsForm from "../components/SheetsForm";

const Membership = () => {
  return (
    <Layout>
      <div className=" py-16 sm:py-24 mt-16 bg-gray-50 border-b-2">
        <div className="sm:text-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold uppercase text-gray-600 sm:text-4xl lg:text-4xl mb-4 sm:mb-12">
            Become a Member
          </h2>
          <p className=" sm:text-xl font-medium text-gray-600">
            Joining the Tasmanian Women Lawyers is easy. Choose the level of
            membership that's right for you.
          </p>
          <p className="mt-4 sm:text-xl font-medium text-gray-600">
            Simply complete the form below and proceed to making your payment
            via credit card.
          </p>
          <p className="mt-4 sm:text-xl font-medium text-gray-600">
            You can choose to have your membership renew annually or purchase a
            one time membership. Annual memberships will renew on 1 July each
            year, starting 1 July 2025. Our membership fees are reviewed and set
            at our Annual General Meeting held in or about November.
          </p>
        </div>
      </div>
      <main className="container mx-auto max-w-5xl my-12 sm:my-24 px-4 w-full overflow-hidden">
        {/* <MembershipForm /> */}
        <SheetsForm/>
      </main>
    </Layout>
  );
};

export default Membership;
