import React, { useState, useEffect } from "react";
import { Dialog, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";

import { graphql, navigate, useStaticQuery } from "gatsby";
import PaymentButton from "./PaymentButton";

const membershipTypes = [
  {
    id: 1,
    title: "Ordinary Membership",
    description: "View eligibility",
    price: "$55.00/year",
    payable: "$55.00",
  },
  {
    id: 2,
    title: "Associate Membership",
    description: "View eligibility",
    price: "$44.00/year",
    payable: "$44.00",
  },
  {
    id: 3,
    title: "Student Membership",
    description: "View eligibility",
    price: "$16.50/year",
    payable: "$0.00",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SheetsForm = () => {
  const data = useStaticQuery(graphql`
    {
      allStripePrice(
        sort: { order: ASC, fields: type }
        filter: { unit_amount: { gt: 100 } }
        limit: 5
      ) {
        edges {
          node {
            id
            active
            currency
            unit_amount
            type
            product {
              id
              name
              metadata {
                intro
                list1
                list2
                list3
                outro
                note
              }
            }
          }
        }
      }
    }
  `);

  //   DEFINE PRODUCTS
  const generateCustomerReferenceId = () => {
    const randomId = Math.random().toString(36).substring(2, 10);
    const timestamp = Date.now().toString(36).substring(2, 10);
    return randomId + timestamp;
  };
  const [customerReferenceId, setCustomerReferenceId] = useState(null);

  useEffect(() => {
    // Generate a new ID when the component mounts
    const newCustomerReferenceId = generateCustomerReferenceId();
    setCustomerReferenceId(newCustomerReferenceId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const getTodaysDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const today = getTodaysDate();

  const products = data.allStripePrice.edges;

  const [selectedMembershipTypes, setSelectedMembershipTypes] = useState(
    membershipTypes[0]
  );
  const [enabledMailingList, setEnabledMailingList] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);

  const activeMembership = {
    title: selectedMembershipTypes.title,
    description: "View eligibility",
    price: selectedMembershipTypes.price,
    payable: selectedMembershipTypes.payable,
    recurring: selectedMembershipTypes != membershipTypes[2] ? enabled : false,
  };

  const [stripeRef, setStripeRef] = useState("0");

  useEffect(() => {
    if (activeMembership.title === "Student Membership") {
      setStripeRef(2);
    } else if (activeMembership.title === "Associate Membership") {
      if (activeMembership.recurring === true) {
        setStripeRef(4);
      } else {
        setStripeRef(1);
      }
    } else if (activeMembership.title === "Ordinary Membership") {
      if (activeMembership.recurring === true) {
        setStripeRef(3);
      } else {
        setStripeRef(0);
      }
    } else {
      setStripeRef(null);
    }
    setOpen(false);
  }, [activeMembership.title, activeMembership.recurring]);

  //   DEFINE NEW MEMBER
  const [email, setEmail] = useState("");
  const [firm, setFirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [gender, setGender] = useState("Female");
  const [lastName, setLastName] = useState("");
  const mailingList = enabledMailingList;
  const [membershipStatus, setMembershipStatus] = useState("pending");
  const membershipType = selectedMembershipTypes.title;
  const [phone, setPhone] = useState("");
  const [recurring, setRecurring] = useState(true);
  const [status, setStatus] = useState("Admitted");
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [workAddress, setWorkAddress] = useState("");

  const [loading, setLoading] = useState(false);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);
    fetch(
      "https://script.google.com/macros/s/AKfycbz4-7BfeU5-syFDFodQAXw2I2c9HJkDCm74QkIlNJmtkrRaWZKenfZ_QRWoXMQ2N3tpNQ/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
    await timeout(1000);
    setOpen(true);
    setLoading(false);
  };

  return (
    <>
      <form className="form" onSubmit={onSubmit}>
        <input
          type="text"
          name="membershipStatus"
          hidden
          value={membershipStatus}
        />
        <input
          type="text"
          name="membershipType"
          hidden
          value={membershipType}
        />
        <input
          type="text"
          name="recurring"
          hidden
          value={activeMembership.recurring}
        />
        <input type="text" name="date" hidden value={today} />
        <input
          type="text"
          name="referenceId"
          hidden
          value={customerReferenceId}
        />
        <input type="text" name="mailingList" hidden value={mailingList} />
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <RadioGroup
              value={selectedMembershipTypes}
              onChange={setSelectedMembershipTypes}
            >
              <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
                Select Membership Level
              </RadioGroup.Label>

              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {membershipTypes.map((membershipType) => (
                  <RadioGroup.Option
                    key={membershipType.id}
                    value={membershipType}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "border-twl-purple ring-2 ring-twl-purple"
                          : "border-gray-300",
                        "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-md font-semibold text-gray-900"
                            >
                              {membershipType.title}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="a"
                              href="/#memberships"
                              className="mt-1 flex items-center text-sm text-gray-500 hover:underline w-24"
                            >
                              {membershipType.description}
                            </RadioGroup.Description>
                            <RadioGroup.Description
                              as="span"
                              className="mt-6 text-md font-medium text-gray-900"
                            >
                              {membershipType.price}
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? "invisible" : "",
                            "h-5 w-5 text-twl-purple"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? "border" : "border-2",
                            checked
                              ? "border-twl-purple"
                              : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>

            {selectedMembershipTypes != membershipTypes[2] ? (
              <Switch.Group as="div" className="flex items-center mt-12">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-twl-purple" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-twl-purple focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900 flex gap-1 items-center">
                    Renew my membership annually{" "}
                  </span>{" "}
                  {/* <span className="text-gray-500">(Save 10%)</span> */}
                </Switch.Label>
                <div class="group cursor-pointer relative inline-block z-20 ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 stroke-gray-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                  <div class="opacity-0 w-[320px] sm:w-[400px] bg-gray-50 border border-twl-green shadow-md text-gray-600 font-medium text-sm rounded-xl py-2 absolute z-10 group-hover:opacity-100 -bottom-[125px] sm:-bottom-2 -left-[280px] sm:left-8 px-3 pointer-events-none">
                    <p className="pb-2">
                      <strong>Note:</strong> All memberships are renewed July 1.
                    </p>
                    <p className="pb-2">
                      Student memberships are ineligible for automatic renewal.
                    </p>
                  </div>
                </div>
              </Switch.Group>
            ) : (
              <Switch.Group
                as="div"
                className="flex flex-col sm:flex-row sm:items-center mt-12"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 fill-orange-300"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                  />
                </svg>
                <span className="font-medium text-gray-900 sm:items-center mt-2 sm:mt-0">
                  Student memberships are ineligible for automatic renewal. You
                  will receive an email when your membership is nearing
                  expiration.{" "}
                </span>{" "}
              </Switch.Group>
            )}
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please provide your details to ensure our records are current.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-4 sm:col-start-1">
                <label
                  htmlFor="gender"
                  className="text-sm font-medium leading-6 text-gray-900 flex items-center gap-1"
                >
                  Gender
                  <div class="group cursor-pointer relative inline-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5 stroke-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div class="opacity-0 w-[320px] sm:w-[400px] bg-gray-50 border border-twl-green shadow-md text-gray-600 font-medium text-sm rounded-xl py-2 absolute z-10 group-hover:opacity-100 -bottom-[220px] sm:-bottom-2 -left-[50px] sm:left-8 px-3 pointer-events-none">
                      <p className="pb-2">
                        <strong>Note:</strong> Only women (including anyone who
                        identifies as a woman) are eligible for Ordinary
                        Membership and Student Membership.
                      </p>
                      <p className="pb-2">
                        If you are not a woman, do not identify as a woman,
                        identify as non-binary, or would prefer not to state
                        your gender, we would be glad to accept your application
                        for Associate Membership.
                      </p>
                    </div>
                  </div>
                </label>
                <div className="mt-2">
                  {selectedMembershipTypes != membershipTypes[1] ? (
                    <select
                      id="gender"
                      name="gender"
                      className="mt-2 w-48 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-twl-purple sm:text-sm sm:leading-6"
                      defaultValue="Female"
                      value="Female"
                      onChange={(e) => setGender(e.currentTarget.value)}
                    >
                      <option>Female</option>
                      <option disabled>Male</option>
                      <option disabled>Other / Prefer not specify</option>
                    </select>
                  ) : (
                    <select
                      id="gender"
                      name="gender"
                      className="mt-2 w-48 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-twl-purple sm:text-sm sm:leading-6"
                      defaultValue="Female"
                      value={gender}
                      onChange={(e) => setGender(e.currentTarget.value)}
                    >
                      <option>Female</option>
                      <option>Male</option>
                      <option>Other / Prefer not specify</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="firm"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Firm / Organisation
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="firm"
                    id="firm"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                    value={firm}
                    onChange={(e) => setFirm(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="work-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Work Address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="workAddress"
                    id="workAddress"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                    value={workAddress}
                    onChange={(e) => setWorkAddress(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Status
                </label>
                <div className="mt-2">
                  <select
                    id="status"
                    name="status"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-twl-purple sm:text-sm sm:leading-6"
                    defaultValue="Admitted"
                    value={status}
                    onChange={(e) => setStatus(e.currentTarget.value)}
                  >
                    <option>Admitted</option>
                    <option>Not Admitted</option>
                    <option>Student</option>
                  </select>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone
                </label>
                <div className="mt-2">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="phone"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                    value={phone}
                    onChange={(e) => setPhone(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10">
              <label
                htmlFor="comment"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                How did you hear about us?
              </label>
              <div className="mt-2">
                <textarea
                  rows={4}
                  name="source"
                  id="source"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-twl-purple sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
        </div>
        {open ? (
          ""
        ) : (
          <div className="mt-6 sm:pb-12 flex flex-col sm:flex-row sm:items-center justify-between gap-x-6">
            <Switch.Group as="div" className="flex items-center pb-8 sm:pb-0">
              <Switch
                checked={enabledMailingList}
                onChange={setEnabledMailingList}
                className={classNames(
                  enabledMailingList ? "bg-twl-purple" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-twl-purple focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabledMailingList ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">
                  Do you want to be a part of our members mailing list?
                </span>{" "}
                {/* <span className="text-gray-500">(Save 10%)</span> */}
              </Switch.Label>
            </Switch.Group>
            {loading ? (
              <button
                disabled={loading}
                className="w-44 flex sm:w-auto rounded-md bg-twl-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-twl-purple-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-twl-purple cursor-pointer"
              >
                <svg
                  className="animate-spin h-5 w-5 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M3.05469 13H5.07065C5.55588 16.3923 8.47329 19 11.9998 19C15.5262 19 18.4436 16.3923 18.9289 13H20.9448C20.4474 17.5 16.6323 21 11.9998 21C7.36721 21 3.55213 17.5 3.05469 13ZM3.05469 11C3.55213 6.50005 7.36721 3 11.9998 3C16.6323 3 20.4474 6.50005 20.9448 11H18.9289C18.4436 7.60771 15.5262 5 11.9998 5C8.47329 5 5.55588 7.60771 5.07065 11H3.05469Z"></path>
                </svg>
                Processing...
              </button>
            ) : (
              <button className="w-44 sm:w-auto rounded-md bg-twl-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-twl-purple-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-twl-purple cursor-pointer">
                Confirm Details
              </button>
            )}
          </div>
        )}
      </form>
      {open ? (
        <>
          <section
            aria-labelledby="summary-heading"
            className="mt-12 rounded-lg bg-twl-purple/5 px-4 py-6 sm:p-6 lg:col-span-5"
          >
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900"
            >
              Order summary
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between">
                <dt className="text-sm text-gray-600">Selected Membership</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {activeMembership.title}
                </dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="flex items-center text-sm text-gray-600">
                  <span>Membership Price</span>
                </dt>
                <dd className="text-sm font-medium text-gray-900">
                  {activeMembership.price}
                </dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="flex text-sm text-gray-600">
                  <span>Automatic Renewal</span>
                </dt>
                <dd className="text-sm font-medium text-gray-900">
                  {activeMembership.recurring ? "Yes" : "No"}
                </dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="text-base font-medium text-gray-900">
                  Order total
                </dt>
                <dd className="text-base font-medium text-gray-900">
                  {activeMembership.payable}
                </dd>
              </div>
            </dl>
          </section>{" "}
          {stripeRef === 2 ? (
            <div className="mt-6 flex flex-col">
              <p>
                No payment required. You will be notified when your
                complimentary student membership has expired.
              </p>
              <button
                className="mt-6 w-44 rounded-md bg-twl-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-twl-purple-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-twl-purple cursor-pointer"
                onClick={() => navigate("/success")}
              >
                Click to confirm
              </button>
            </div>
          ) : (
            <PaymentButton
              product={products[stripeRef].node}
              stripeRef={customerReferenceId}
            />
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SheetsForm;
